"use strict";

function _getUrlParameters() {
    var orig = "";
    var params = "";
    var url = window.location.toString();
    if (url.indexOf("orig=") >= 0) {
        if(url.substring(url.indexOf("orig=")+5).indexOf("&") !== -1) {
            orig = decodeURIComponent(url.substring(url.indexOf("orig=")+5, url.indexOf("orig=")+6+url.substring(url.indexOf("orig=")+5).indexOf("&")));
        } else {
            orig = decodeURIComponent(url.substring(url.indexOf("orig=")+5));
        }
        return orig;
    } else if (url.indexOf("login=") === -1) {
        return "";
    } else {
        return "";
    }
}

/**
 * @description remove all cookies
 * @function
 */
function _removeOtherCookies () {
    var cookiesToLive = ["user_id", "user_hash", "expiration_date", "csrf-token", "cf_sidebar_displaymode"];
    var pairs = document.cookie.split(";");
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        var cookieKey = pair[0].trim();
        if (typeof cookieKey === "string" && (cookieKey.length > 0) && (cookiesToLive.includes(cookieKey) === false)) {
            api_util.set_cookie(cookieKey, null); // remove it
        }
    }
}

/**
 * @description set all the needed cookies
 * @function
 * @param {Object} pData object containing data to set the cookies
 */
function _setCookies (pData) {
    // Store our login in a cookie
    var secureOptions = { 
        expires: 2, // default 2 days
        path: "/" 
    };
    if (document.location.protocol === "https:") {
        secureOptions.secure = true;
    }
    if (pData.expiration_date !== undefined) {
        secureOptions.expires = (pData.expiration_date - new Date().getTime() / 1000) / 86400 + 1;
    }
    api_util.set_cookie("user_id", pData.user_id, secureOptions);
    api_util.set_cookie("user_hash", pData.user_hash, secureOptions);
    api_util.set_cookie("expiration_date", pData.expiration_date, secureOptions);
    api_util.set_cookie("csrf-token", pData.csrf_token, secureOptions);
    if (pData.scope_id !== undefined) {
        api_util.set_cookie("scope", pData.scope_id, { expires: secureOptions.expires, path: "/" });
    } 
}

function doMFALogin(pUsername, pAttemptID, pCode, pSuccess, pFailure) {
    if (typeof pAttemptID !== "string" || pAttemptID.length <= 0) {
        throw new Error("missing id");
    }
    if (typeof pCode !== "string" || pCode.length <= 0) {
        throw new Error("missing code");
    }
    _removeOtherCookies();
    var nakedCode = pCode.replace(/\W/g, ""); // remove all whitespaces
    var login_options = { 
        "attempt_id": pAttemptID,
        "mfa_code": nakedCode
    };
    api_async.auth.mfa_login(pUsername, login_options, function (pData) {
        if (pData.user_id === undefined) {
            pFailure($.i18n._("nixps-cloudflow-login.error-login_failed"));
        } else {
            _setCookies(pData);
            pSuccess(pData.user_id);
        }
    }, function (pError) {
        // all errors who are accepted and just mean, continue
        if (pError && ["missing_scope"].includes(pError.error_code)) {
            pFailure(pError);
        } else if (pError.error_code !== undefined) {
            var translatedError = $.i18n._("nixps-cloudflow-login.error-" + pError.error_code);
            if (translatedError === ("nixps-cloudflow-login.error-" + pError.error_code)) {
                // untranslateded error
                pFailure("generic error");
            } else {
                pFailure(translatedError);
            }
        } else if (pError.error !== undefined) {
            pFailure(pError.error);
        }
    });
}

function doMultiScopeLogin(pUsername, pPassword, pScope, pSuccess, pFailure) {
    doLoginWithOptions(pUsername, pPassword, pScope, pSuccess, pFailure);
}

function doLoginWithOptions(pUsername, pPassword, pScope, pSuccess, pFailure) {
    _removeOtherCookies();
    var login_options = {"password": pPassword};
    if (pScope !== "") {
        login_options.scope_name = pScope;
    }
    api_async.auth.login_with_options(pUsername, login_options, function (pData) {
        if (pData.user_id === undefined) {
            pFailure($.i18n._("nixps-cloudflow-login.error-login_failed"));
        } else {
            _setCookies(pData);
            pSuccess(pData.user_id);
        }
    }, function (pError) {
        // all errors who are accepted and just mean, continue
        if (pError && ["missing_scope", "mfa_code_required"].includes(pError.error_code)) {
            pFailure(pError);
        } else if (pError.error_code !== undefined) {
            var translatedError = $.i18n._("nixps-cloudflow-login.error-" + pError.error_code);
            if (translatedError === ("nixps-cloudflow-login.error-" + pError.error_code)) {
                // untranslateded error
                pFailure("generic error");
            } else {
                pFailure(translatedError);
            }
        } else if (pError.error !== undefined) {
            pFailure(pError.error);
        }
    });
}

function doLogin (pUsername, pPassword, pSuccess, pFailure) {
    api_defer.preferences.get_for_current_user("com.nixps.general.prerelease", "protected_cookies").then(function(pPreference){
        if (pPreference.preferences === true) {
            doLoginwithRedirect(pUsername, pPassword, pSuccess, pFailure);
        } else {
            doLoginWithOptions(pUsername, pPassword, "", pSuccess, pFailure);
        }
        
    }, function(pError) {
        console.error(pError);
        doLoginwithRedirect(pUsername, pPassword);
    });
    
}

function getVersionText (pVersionData) {
    var version = pVersionData.major + "." + pVersionData.minor;

    if (pVersionData.rev !== 0) {
        version += "." + pVersionData.rev;
    }

    var versionText = $.i18n._("nixps-cloudflow-login.version", [version, pVersionData.build]);
    return versionText;
}

function setFrameSession (userId) {
    return $.Deferred(function (pDefer) {
        api_defer.frame.os.get().then(function () {
            api_defer.auth.get_current_user().then(function (currentUser) {
                return api_defer.auth.create_session(currentUser.username);
            }).then(function (sessionKey) {
                var session = sessionKey.session;
                return api_defer.frame.os.set_cloudflow_session(session, {"force": true, "expiry_time": sessionKey.expiry_time});
            }).then(function () {
                pDefer.resolve();
            }).fail(function () {
                pDefer.resolve();
            });
        }).fail(function () {
            pDefer.resolve();
        });
    });
}

function doLoginwithRedirect(pUsername, pPassword, pSuccess, pFailure) {
    function url_redirect(options){
        var $form = $("<form />");
        
        $form.attr("action",options.url);
        $form.attr("method",options.method);
        
        for (var data in options.data) {
            $form.append('<input type="hidden" name="'+data+'" value="'+options.data[data]+'" />');
        }
        
        $("body").append($form);
        $form.submit();
        $form.remove();
    }

    _removeOtherCookies();
    return api_defer.auth.create_session(pUsername, {password: pPassword}).then(function(pResult){
        url_redirect({
            url: window.location.origin+"/portal.cgi?auth=login",
            method: "POST",
            data: {"session":pResult.session, "orig": _getUrlParameters()}
        });
        
    }, function(pError){
        if (pError && ["missing_scope", "mfa_code_required"].includes(pError.error_code)) {
            pFailure(pError);
        } else {
            pFailure(pError.error);
        }  
    });
}
    

module.exports = {
    doLogin: doLogin,
    doMFALogin: doMFALogin,
    doMultiScopeLogin: doMultiScopeLogin,
    getVersionText: getVersionText,
    setFrameSession: setFrameSession
}
