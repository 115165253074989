var CloudflowURL = require("./CloudflowURL.js");
var Query = require("../../ManageUsers/js/Query.js");
var QueryApplier = require("./QueryApplier.js");
var CloudflowObject = require("./CloudflowObject.js");

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
};
function generateUUID() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

function timeStamp() {
    return (new Date()).toISOString().substring(0, 19) + "Z";
}

function FileConnection(pURL, pRelativeBasePath, pContentType) {
    this.loadingDef;
    this.content;
    this.fileEtag;
    this.fileModification;
    this.error;
    this.autoAdaptedURL; // log to trase url change
    this.dirty = false;
    this.reasonToStop = null;
    this.setURL(pRelativeBasePath, pURL);
    this.setContentType(pContentType);
}

FileConnection.fromJSON = function(json) {
    return new FileConnection(json.url, json.relativeBasePath, json.contentType);
};

FileConnection.prototype = {

    constructor: FileConnection,

    setURL: function(pRelativeBasePath, pURL) {
        if (typeof pURL !== "string" || pURL.length <= 0) {
            throw new Error("parameter url must be a not empty string");
        }
        this.url = pURL;
        this.relativeBasePath = pRelativeBasePath;
        this.cloudflowURL = new CloudflowURL(this.relativeBasePath, this.url, true);
    },

    getURL: function() {
        return this.url;
    },

    getRelativeBasePath: function(){
        return this.relativeBasePath;
    },

    setContentType: function(pContentType){
        if (pContentType === undefined || pContentType === "json") {
            this.contentType = "json";
        } else {
            throw new Error("unsupported contentType " + pContentType);
        }
    },

    getContentType: function(){
        return this.contentType;
    },

    getError: function() {
        return this.error;
    },

    _setError: function(pErrorCode, pError, pXHR) {
        var returnErrorObj;
        if (pXHR !== undefined && typeof pXHR.responseText === "string") {
            try {
                returnErrorObj = JSON.parse(pXHR.responseText);
            } catch(e) {}
        }
        if (pErrorCode === "error" && pError === "Not Found") {
            this.error = {
                "error_code": "file_not_found",
                "error": "File not found"
            };
            if (returnErrorObj !== undefined && returnErrorObj.messages !== undefined && Array.isArray(returnErrorObj.messages.messages) &&
                returnErrorObj.messages.messages.length > 0) {
                    this.error.error = returnErrorObj.messages.messages[0].description;
                }
        } else if (returnErrorObj !== undefined) {
            this.error = returnErrorObj;
        } else {
            this.error = {
                "error_code": pErrorCode,
                "error": pError
            };
        }
    },

    /**
     * @function
     * @description Read the content of the file
     */
    load: function(){
        var that = this;
        var getUrl = "/portal.cgi?asset=download_file&url=" + encodeURIComponent(this.cloudflowURL.getCloudflowPath());
        this.loadingDef = $.ajax({
            method: "GET",
            type: "GET",
            async: true,
            url: getUrl,
            dataType: this.contentType,
            // timeout: 0,
            success: function(pData, pStatus, jqXHR) {
                if (pStatus === "success") {
                    that.content = pData;
                    var etagFormat = jqXHR.getResponseHeader("etag");
                    if (typeof etagFormat === "string" && etagFormat.length > 0) {
                        if (etagFormat[0] === '"') {
                            that.fileEtag = etagFormat.slice(1, -1);
                        } else {
                            that.fileEtag = etagFormat;
                        }
                        if (etagFormat.indexOf("mod=") === 0) {
                            that.fileModification = etagFormat.slice(4);
                        }
                    }
                } else {
                    that.content = null;
                    that._setError(pStatus, "", jqXHR);
                    console.error(pStatus);
                }
            }
        }).then(function(){}, function(jqXHR, pErrorCode, pError){
            if (jqXHR.statusText === "OK" && jqXHR.status === 200 && jqXHR.responseText === "") {
                // empty file
                that.content = [];
                return []; // reset to solved
            }
            that.content = null;
            that._setError(pErrorCode, pError, jqXHR);
            if (pErrorCode === "deprecated_results") {
                return false; // expeceted error
            }
            console.error(pErrorCode, pError);
            return $.Deferred().reject(jqXHR, pErrorCode, pError);
        });
        return this.loadingDef;
    },

    getData: function(){
        return this.content;
    },
    
    getDataObject: function() {
        if ($.isPlainObject(this.content)) {
            return $.extend(true, {}, this.content);
        } else if (Array.isArray(this.content) && this.content.length > 0 && $.isPlainObject(this.content[0])) {
            return $.extend(true, {}, this.content[0]);
        } else {
            return null;
        }
    },

    _guessIdentifierKey: function(pObject) {
        if (pObject["_id"] !== undefined) {
            return "_id";
        } else if (pObject["id"] !== undefined) {
            return "id";
        } else if (pObject["ID"] !== undefined) {
            return "ID";
        } else {
            return null;
        }
    },

    create: function(pObject, pOptions, pGoodCB, pErrorCB) {
        var that = this;
        // get options and apply defaults
        var options = Object.assign({"uniqKeyName": "_id", "addBirth": true}, pOptions);
        // deep copy of object to create
        var objectToCreate = Object.assign({}, pObject);
        this.loadingDef = this.load();
        return $.when(this.loadingDef).then(function() {
            var indentifierKey = options.uniqKeyName;
            if (Array.isArray(that.content)) {
                // guess the id to be used
                if (that.content.length > 0) {
                    indentifierKey = that._guessIdentifierKey(that.content[that.content.length - 1]);
                    if (indentifierKey === null) {
                        indentifierKey = options.uniqKeyName;
                    }
                }
            } else {
                // make new content
                that.content = [];
            }
            // generate and add id
            objectToCreate[indentifierKey] = generateUUID();
            // add birth key
            if (options.addBirth === true) {
                objectToCreate["birth"] = timeStamp();
            }
            // append to other rows
            that.content.push(objectToCreate);
            // save
            return that.update_all(that.content, function(){
                // good callback
                if (typeof pGoodCB === "function") {
                    pGoodCB(objectToCreate);
                }
                return objectToCreate;
            }).fail(pErrorCB);
        }).fail(function(jqXHR, pErrorCode, pError){
            if (typeof pErrorCB === "function") {
                pErrorCB({error: pErrorCode, error_message: pError});
            }
        });
    },

    list_with_options: function(pQuery, pOrderby, pFields, pOptions, pGoodCB, pErrorCB) {
        var that = this;
        var skip = 0;
        var limit = undefined;
        var last = undefined;
        if (pOptions !== undefined) {
            //  {first: pSkip, maximum: pLimit}
            if (pOptions.first !== undefined) {
                skip = parseInt(pOptions.first);
            }
            if (pOptions.maximum !== undefined) {
                limit = parseInt(pOptions.maximum);
            }
            if (pOptions.last !== undefined) {
                last = parseInt(pOptions.last);
            }
        }
        this.loadingDef = this.load();
        return $.when(this.loadingDef).fail(function(jqXHR, pErrorCode, pError){
            if (typeof pErrorCB === "function") {
                pErrorCB({error: pErrorCode, error_message: pError});
            }
        }).then(function() {
            var contentRows = $.makeArray(that.content);
            var rows = [];
            var returnObject = {};
            if (Array.isArray(pQuery) && pQuery.length > 0) {
                var queryApplier;
                var filteredData;
                try {
                    queryApplier = new QueryApplier(pQuery);
                    filteredData = queryApplier.apply(contentRows);
                } catch(e) {
                    if (typeof pErrorCB === "function") {
                        pErrorCB({error: "invalid_query", error_message: e});
                    }
                    return $.Deferred().reject(e);
                }
                rows = Query.apply(filteredData, [], pOrderby, skip, limit, last);
                returnObject = {results: rows};
                if (pOptions.count === true) {
                    returnObject.count = filteredData.length;
                }
            } else {
                rows = Query.apply(contentRows, [], pOrderby, skip, limit, last);
                returnObject = {results: rows};
                if (pOptions.count === true) {
                    returnObject.count = contentRows.length;
                }
            }
            if (typeof pGoodCB === "function") {
                pGoodCB(returnObject);
            }
            return rows;
        });
    },

    _update: function(pQuery, pUpdateObject, pMerge, pOneLevelMerge, pFirstOnly){
        var queryApplier = new QueryApplier(pQuery);
        var count_updated = 0;
        var error = undefined;
        for (var i=0; i<this.content.length; i++) {
            if (queryApplier.validate(this.content[i]) === true) {
                if (this.content[i] !== undefined && pMerge === false) { 
                    if (this.content[i].save_id !== undefined) {
                        if (this.content[i].save_id !== pUpdateObject.save_id) {
                            this._setError("save_id_not_equal", "the save_id is not equal");
                            error = this.getError();
                            if (pFirstOnly === true) {
                                return {success: false, count_updated: count_updated, error: error};
                            } else {
                                continue;
                            }
                        } else {
                            pUpdateObject.save_id = generateUUID();
                        }
                    }
                    if (typeof this.content[i].modification === "string" && this.content[i].modification.length > 17 && isNaN(new Date(this.content[i].modification)) === false) {
                        // update modification key 
                        pUpdateObject.modification = timeStamp();
                    }
                }
                if (pMerge === true) {
                    if (this.content[i].save_id !== undefined && this.content[i].save_id !== pUpdateObject.save_id) {
                        this._setError("save_id_not_equal", "the save_id is not equal");
                        error = this.getError();
                        if (pFirstOnly === true) {
                            return {success: false, count_updated: count_updated, error: error};
                        } else {
                            continue;
                        }
                    }
                    // merge with existing 
                    if (pOneLevelMerge === false) {
                        this.content[i] = $.extend(true, {}, this.content[i], pUpdateObject);
                    } else {
                        // keys can contain dots notations
                        for (var key in pUpdateObject) {
                            if (pUpdateObject.hasOwnProperty(key)) {
                                this.content[i] = CloudflowObject.setParameter(this.content[i], key, pUpdateObject[key]);
                            }
                        }
                    }
                    if (this.content[i].save_id !== undefined) {
                        // no check for the moment
                        this.content[i].save_id = generateUUID();
                    }
                    if (typeof this.content[i].modification === "string" && this.content[i].modification.length > 17 && isNaN(new Date(this.content[i].modification)) === false) {
                        // update modification key 
                        this.content[i].modification = timeStamp();
                    }
                } else {
                    // replace with existing
                    this.content[i] = pUpdateObject;
                }
                count_updated++;
                if (pFirstOnly === true) {
                    break;
                }
            }
        }
        return {success: count_updated > 0, count_updated: count_updated, error: error};
    },


    update: function(pUpdateObject, pFindQuery, pGoodCB, pErrorCB) {
        var updateObject = $.extend(true, {}, pUpdateObject);
        if (this.content === null) {
            this.content = [updateObject];
            if (typeof pGoodCB === "function") {
                pGoodCB(updateObject);
            }
            return $.Deferred().resolve(updateObject);
        }
        var searchQuery;
        if (Array.isArray(pFindQuery) && pFindQuery.length > 2) {
            searchQuery = pFindQuery;
        } else {
            var key = this._guessIdentifierKey(updateObject);
            if (typeof key === "string" && key.length > 0) {
                searchQuery = [key, "equal to", updateObject[key]];
            } else {
                this._setError("identification_not_found", "no identification has not been found");
                if (typeof pErrorCB === "function") {
                    pErrorCB(this.getError());
                }
                return $.Deferred().reject(this.getError());
            }
        }
        if (Array.isArray(searchQuery) && searchQuery.length > 2) {
            var result = this._update(searchQuery, updateObject, false, false, true);
            if (result.error !== undefined) {
                if (typeof pErrorCB === "function") {
                    pErrorCB(result.error);
                }
                return $.Deferred().reject(result.error);
            }
            if (result.success === false) {
                this._setError("object_not_found", "the updated object has not been found");
                if (typeof pErrorCB === "function") {
                    pErrorCB(this.getError());
                }
                return $.Deferred().reject(this.getError());
            } else {
                this.update_all(this.content, function(){
                    if (typeof pGoodCB === "function") {
                        pGoodCB(updateObject);
                    }
                    return $.Deferred().resolve(updateObject);
                }, function(pError){
                    if (typeof pErrorCB === "function") {
                        pErrorCB(pError);
                    }
                    return $.Deferred().reject(pError);
                });
            }
        } else {
            this._setError("object_not_found", "the updated object has not been found");
            if (typeof pErrorCB === "function") {
                pErrorCB(this.getError());
            }
            return $.Deferred().reject(this.getError());
        }
    },

    update_all: function(pRows, pGoodCB, pErrorCB) {
        if (Array.isArray(pRows)) {
            var that = this;
            var content = JSON.stringify(pRows, null, 3);
            return $.when(this._save(content)).then(function(){
                if (typeof pGoodCB === "function") {
                    pGoodCB();
                }
            }, function(pError){
                if (pError !== undefined && pError.error_code !== undefined) {
                    that._setError(pError.error_code, pError.error);
                }
                if (typeof pErrorCB === "function") {
                    pErrorCB(pError);
                }
                return $.Deferred().reject(that.getError());
            });
        } else {
            this._setError("wrong_parameter", "content must be an array");
            if (typeof pErrorCB === "function") {
                pErrorCB(this.getError());
            }
            return $.Deferred().reject(this.getError());
        }
        
    },

    count: function(pQuery, pGoodCB, pErrorCB) {
        var that = this;
        this.loadingDef = this.load();
        return $.when(this.loadingDef).then(function() {
            var contentRows = $.makeArray(that.content);
            var count;
            if (Array.isArray(pQuery) && pQuery.length > 0) {
                var queryApplier = new QueryApplier(pQuery);
                count = queryApplier.apply(contentRows).length;
            } else {
                count = contentRows.length;
            }
            if (typeof pGoodCB === "function") {
                pGoodCB({count: count});
            }
            return count;
        }, function(jqXHR, pErrorCode, pError){
            if (typeof pErrorCB === "function") {
                pErrorCB({error: pErrorCode, error_message: pError});
            }
            return $.Deferred().reject(that.getError());
        });
    },
    
    set_keys: function(pID, pKeyData, pGoodCB, pErrorCB){
        var that = this;
        this.loadingDef = this.load();
        return $.when(this.loadingDef).then(function() {
            var contentRows = $.makeArray(that.content);
            if (Array.isArray(contentRows) && contentRows.length > 0) {
                var key = that._guessIdentifierKey(contentRows[0]);
                if (key === null) {
                    that._setError("identification_not_found", "no identification has not been found");
                    if (typeof pErrorCB === "function") {
                        pErrorCB(that.getError());
                    }
                } else {
                    var result = that._update([key, "equal to", pID], pKeyData, true, true, true);
                    if (result.count_updated > 0) {
                        // only update if some data changed
                        return that.update_all(that.content).then(function(){
                            if (result.error !== undefined) {
                                if (typeof pErrorCB === "function") {
                                    pErrorCB(result.error);
                                }
                                return $.Deferred().reject(result.error);
                            } else {
                                if (typeof pGoodCB === "function") {
                                    pGoodCB({});
                                }
                                return $.Deferred().resolve({});
                            } 
                        }).fail(pErrorCB);
                    } else if (result.error !== undefined) {
                        if (typeof pErrorCB === "function") {
                            pErrorCB(result.error);
                        }
                        return $.Deferred().reject(result.error);
                    } else {
                        that._setError("object_not_found", "no object found with id " + pID);
                        if (typeof pErrorCB === "function") {
                            pErrorCB(that.getError());
                        }
                    }
                }
            } else {
                that._setError("object_not_found", "no object found with id " + pID);
                if (typeof pErrorCB === "function") {
                    pErrorCB(that.getError());
                }
            } 
        }, function(jqXHR, pErrorCode, pError){
            if (typeof pErrorCB === "function") {
                pErrorCB({error: pErrorCode, error_message: pError});
            }
            return $.Deferred().reject(that.getError());
        });
    },

    set_keys_by_query: function(pQuery, pKeyData, pOptions, pGoodCB, pErrorCB) {
        var that = this;
        this.loadingDef = this.load();
        return $.when(this.loadingDef).then(function(){
            if (that.content === null) {
                // success even if no items are found
                if (typeof pGoodCB === "function") {
                    pGoodCB({});
                }
                return $.Deferred().resolve({});
            }
            var result = that._update(pQuery, pKeyData, true, true, false);
            if (result.count_updated > 0) {
                // only update if some data changed
                return that.update_all(that.content).then(function(){
                    if (result.error !== undefined) {
                        if (typeof pErrorCB === "function") {
                            pErrorCB(result.error);
                        }
                        return $.Deferred().reject(result.error);
                    } else {
                        if (typeof pGoodCB === "function") {
                            pGoodCB({});
                        }
                        return $.Deferred().resolve({});
                    } 
                }).fail(pErrorCB);
            } else if (result.error !== undefined) {
                if (typeof pErrorCB === "function") {
                    pErrorCB(result.error);
                }
                return $.Deferred().reject(result.error);
            } else {
                // success even if no items are found
                if (typeof pGoodCB === "function") {
                    pGoodCB({});
                }
                return $.Deferred().resolve({});
            }
        }, function(jqXHR, pErrorCode, pError){
            if (typeof pErrorCB === "function") {
                pErrorCB({error: pErrorCode, error_message: pError});
            }
            return $.Deferred().reject(that.getError());
        });
    },

    delete: function(pID, pGoodCB, pErrorCB){
        var that = this;
        this.loadingDef = this.load();
        return $.when(this.loadingDef).then(function() {
            var contentRows = $.makeArray(that.content);
            if (Array.isArray(contentRows) && contentRows.length > 0) {
                var key = that._guessIdentifierKey(contentRows[0]);
                if (key === null) {
                    that._setError("identification_not_found", "no identification has not been found");
                    if (typeof pErrorCB === "function") {
                        pErrorCB(that.getError());
                    }
                } else {
                    return that._delete_by_query([key, "equal to", pID], pGoodCB, pErrorCB);
                }
            } 
        }, function(jqXHR, pErrorCode, pError){
            if (typeof pErrorCB === "function") {
                pErrorCB({error: pErrorCode, error_message: pError});
            }
            return $.Deferred().reject(that.getError());
        });
    },

    delete_by_query: function(pQuery, pGoodCB, pErrorCB){
        var that = this;
        this.loadingDef = this.load();
        return $.when(this.loadingDef).then(function() {
            return that._delete_by_query(pQuery, pGoodCB, pErrorCB);
        }, function(jqXHR, pErrorCode, pError){
            if (typeof pErrorCB === "function") {
                pErrorCB({error: pErrorCode, error_message: pError});
            }
            return $.Deferred().reject(that.getError());
        });
    },

    _delete_by_query: function(pQuery, pGoodCB, pErrorCB){
        var contentRows = $.makeArray(this.content);
        var deleted_ids = [];
        var removedItem, removedKey;
        if (Array.isArray(pQuery) && pQuery.length > 0) {
            var queryApplier = new QueryApplier(pQuery);
            for (var i=contentRows.length; i>=0; i--) {
                if (queryApplier.validate(contentRows[i])) {
                    // item to remove found
                    removedItem = contentRows.splice(i, 1);
                    removedKey = this._guessIdentifierKey(removedItem[0]);
                    if (typeof removedKey === "string" && removedKey.length > 0) {
                        deleted_ids.push(removedItem[0][removedKey]);
                    } else {
                        console.error("remove key path not found");
                    }
                }
            }
        } 
        return this.update_all(contentRows, function(){
            if (typeof pGoodCB === "function") {
                pGoodCB({deleted_ids: deleted_ids});
            }
        }, pErrorCB);
    },

    touch: function(){
        var that = this;
        return $.Deferred(function(pDefer){
            api_async.file.does_exist(that.cloudflowURL.getCloudflowPath(), function(pResult) {
                /*
                    exists: false
                    is_folder: false
                    url: "cloudflow://PP_FILE_STORE/Menu/sitemap%20met%20%c3%a8.json"
                    valid: true
                */
                if (pResult !== undefined) {
                    if (pResult.valid === false) { // folders does not exists, so create them....
                        FileConnection.createParentFolders(pResult.url).then(function(){
                            that.update_all([], function(){
                                that.cloudflowURL = new CloudflowURL(pResult.url);
                                that.autoAdaptedURL = "after_touch";
                                pDefer.resolve({
                                    exists: false,
                                    url: pResult.url,
                                    valid: pResult.valid,
                                });
                            }, pDefer.reject);
                        }, pDefer.reject)
                    } else if (pResult.exists === false) {
                        that.update_all([], function(){
                            that.cloudflowURL = new CloudflowURL(pResult.url);
                            that.autoAdaptedURL = "after_touch";
                            pDefer.resolve({
                                exists: false,
                                url: pResult.url,
                                valid: pResult.valid,
                            });
                        }, pDefer.reject);
                    } else if (pResult.exists === true) {
                        pDefer.resolve(pResult);
                    } else {
                        pDefer.reject("wrong returning result");
                    }
                } else {
                    pDefer.reject("wrong returning result");
                }
            }, pDefer.reject);
        });
    },
    
    _save: function(pContent) {
        var that = this;
        return $.Deferred(function(pDefer) {

			var session = "";
			if (api.m_session !== undefined) {
				session = "&session=" + api.m_session;
			}
            var checks = "";
            if (typeof that.fileEtag === "string" && that.fileEtag.length > 0) {
                if (that.fileEtag.indexOf("mod=") === 0) {
                    checks = "&check_modification=" + that.fileEtag.slice(4);
                } else {
                    checks = "&check_etag=" + that.fileEtag;
                }
            } else if (typeof that.fileModification === "number") {
                checks = "&check_modification=" + that.fileModification;
            }
			var boundary = "----cloudflowfileconnection" + Date.now();
            // attention keep this header clean,
            // header need to use \r\n for new lines
            // header may not have spaces before or after header keys
            var sendContent = "--" + boundary + '\r\nContent-Type: text/json\r\nContent-Disposition: form-data;filename="dumyblabla.json"\r\n\r\n' + pContent;
            sendContent = sendContent + "\r\n--" + boundary + "--\r\n";
            var upload_url = "/portal.cgi?asset=upload_file&url=" + encodeURIComponent(that.cloudflowURL.getCloudflowPath()) + "&overwrite=true" + session + checks;
            var req = $.ajax({
                type: "POST",
                url: upload_url,
                data: sendContent,
                processData: true,
                contentType: "text/plain; charset=UTF-8",
                success: function(output, pStatus, jqXHR) {
                    if (pStatus === "success") {
                        var data = output;
                        if (typeof output === "string" && output.length > 0) {
                            try {
                                data = JSON.parse(output);
                            } catch(e) {
                                pDefer.reject(output);
                            }
                        }
                        if (Array.isArray(data.files)) {
                            if (data.files.length === 1) {
                                // etag has been changed, but what is the new value??
                                that.fileEtag = ""; 
                                if (Array.isArray(data.file_info) && data.file_info.length === 1) {
                                    if (typeof data.file_info[0].etag === "string") {
                                        that.fileEtag = data.file_info[0].etag;
                                    }
                                    if (typeof data.file_info[0].modification === "number") {
                                        that.fileModification = data.file_info[0].modification;
                                    }
                                }
                                pDefer.resolve({
                                    "url": data.files[0],
                                    "content": pContent
                                });
                            } else if (data.files.length === 0) {
                                // file has been ignored!!!
                                pDefer.reject({
                                    "error_code": "Document not saved",
                                    "error": "saving conflict, the file has been modified by somebody else"
                                })
                            } else {
                                // really strange thing
                                pDefer.reject({
                                    "error_code": "unknown_error",
                                    "error": "unknown error"
                                });
                            }
                        }
                    } else {
                        pDefer.reject(output);
                    }
                },
                beforeSend: function(xhr){
                    xhr.setRequestHeader('Content-Type', 'multipart/form-data; boundary=' + boundary);
                } 
            }).fail(function(error) {
                var errorMessage = {
                    "error": "Could not save",
                    "error_code": "faild_saving"
                };
                if (typeof error.responseText === "string") {
                    try { // try to retrieve error message
                        errorMessage = JSON.parse(error.responseText);
                    } catch(e) {}
                }
                pDefer.reject(errorMessage);
            });
        }); 
    },

    
    stop: function(pReason){
        if (this.loadingDef !== undefined && this.loadingDef.readyState > 0 && this.loadingDef.readyState < 4) {
            this.loadingDef.abort("deprecated_results");
        }
        if (typeof pReason === "string") {
            this.reasonToStop = pReason;
        } else {
            this.reasonToStop = "???";
        }
    },

    clone: function(){
        return FileConnection.fromJSON(this.toJSON());
    },

    toJSON: function(){
        return {
            url: this.url,
            relativeBasePath: this.relativeBasePath,
            contentType: this.contentType
        };
    }
};

FileConnection.createParentFolders = function(pURL) {
    if (pURL.indexOf("cloudflow://") === 0) {
        pURL = pURL.slice(12);
    }
    if (pURL.endsWith(".json")){
        // make folder
        pURL = pURL.slice(0, pURL.lastIndexOf("/") + 1);
    }
    var firstSlashIndex = pURL.indexOf('/') + 1;
    var rootFolder = "cloudflow://" + pURL.substring(0, firstSlashIndex); 
    return $.Deferred(function(pDefer) {
        api_async.file.create_folder_with_options(rootFolder, pURL.slice(firstSlashIndex), {unique_name_mode: "None", create_folders: true}, function(result) {
            if (result !== undefined && typeof result.created_folder === "string" && result.created_folder.length > 0) {
                pDefer.resolve({"url": result.created_folder});
            } else {
                pDefer.reject({"error_code": "wrong_return_interface", "error": "wrong return value api.file.create_folder"});
            }
        }, function(pError) {
            console.error(pError);
            pDefer.reject(pError);
        }); 
    });
}


module.exports = FileConnection;