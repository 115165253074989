var TemporyBrowserObjectCache = require("../../CloudflowUtil/js/TemporyBrowserObjectCache.js");
var versionObject;
var versionDef;
/**
 * @constructor Version
 * @description an utility class reprecenting the version of cloudflow. For Example 22.04 update
 * @param {Object} pObject 
 */
function Version(pObject) {
    this._setVersionObject(pObject);
};

/**
 * @name Version.get
 * @function
 * @description Get the version from the backend and fill it in with usefull information
 * @returns {Version}
 */
Version.get = function(){
    if (versionObject === undefined) {
        if (versionDef === undefined) {
            if (TemporyBrowserObjectCache.isPageReload() !== true) {
                var cacheObject = TemporyBrowserObjectCache.get("nixps_cloudflow_version");
                if (cacheObject !== null) {
                    versionObject = cacheObject;
                    return $.when(new Version(versionObject));
                }
            }
            versionDef = loadVersionFromBackend();
        }
        return $.when(versionDef).then(function(pVersionObject){
            versionObject = pVersionObject;
            TemporyBrowserObjectCache.save("nixps_cloudflow_version", versionObject);
            return new Version(versionObject);
        });
    } else {
        return $.when(new Version(versionObject));
    }
}

/**
 * @name Version.clear
 * @description reset all the internal caches
 * @private
 * @fucntion
 */
Version.reset = function(){
    versionObject = undefined;
    versionDef = undefined;
    TemporyBrowserObjectCache.remove("nixps_cloudflow_version");
};

Version.prototype = {
    
    constructor: Version,
    
    _setVersionObject: function(pVersionObject){
        if (pVersionObject !== null && typeof pVersionObject === "object" && !Array.isArray(pVersionObject)) {
            this.versionObject = pVersionObject;
        } else {
            throw new Error("expect an object as parameter");
        }
    },
    
    /**
     * @description Get the complete object represention the version
     * @name Version#getVersionObject
     * @function
     * @returns {Object}
     */
    getVersionObject: function(){
        return this.versionObject;
    },
    
    /**
     * @description Get the build number
     * @function
     * @name Version#getBuild
     * @returns {Number} buildnumber
     */
    getBuild: function(){
        if (this.versionObject.build !== undefined) {
            return this.versionObject.build;
        } 
        return 0;
    },
    
    /**
     * @name Version#toString
     * @function
     * @description Get a textual representaion of the cloudflow version
     * @returns {String} a textual representation
     */
    toString: function(){
        var version = "";
        // control if major and minor number are present, otherwise do nothing.
        if (typeof this.versionObject.major === "number" && this.versionObject.major >= 0 && typeof this.versionObject.minor === "number" && this.versionObject.minor >= 0) {
            version = $.i18n._("nixps-cloudflow-login.version", [this.versionObject.major + "." + this.versionObject.minor, this.versionObject.build]);
            if (typeof this.versionObject.rev === "number" && this.versionObject.rev > 0) {
                version += (" " + $.i18n._("nixps-cloudflow-login.versionupdate", [this.versionObject.rev]));
            }
        }
        return version;
    },
    
    /**
     * @description Get a short notation of the version, that can be used as hash
     * @function
     * @name Version#getHashString
     * @returns {String} A short notation of the version
     */
    getHashString: function(){
        if (this.versionObject === null || this.versionObject === undefined || $.isEmptyObject(this.versionObject)) {
            return "00version0";
        }
        return this.versionObject.major + "" + this.versionObject.minor + "version" + this.versionObject.build;
    },
    
    /**
     * @description The the json of the version object
     * @function
     * @name Version#toString
     * @returns {Object} JSON version of the version object
     */
    toJSON: function(){
        return Object.assign({}, this.versionObject);
    }
};

function loadVersionFromBackend() {
    return $.Deferred(function(pDefer){
        if (window.api_async === undefined) {
            pDefer.resolve({});
            return;
        }
        if (window.api_defer === undefined) {
            pDefer.resolve({});
            return;
        }
        api_async.portal.version(function(pVersion){
            pDefer.resolve(pVersion);
        }, function(pError){
            console.error(pError);
            pDefer.resolve({});
        });
    });
}

module.exports = Version;